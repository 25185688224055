/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react';

import { useDataTable } from '../context/DataTableContext';
import { TdAgregadores } from '../styles';
import { HeadersProps } from '../types';
import { formatValue } from '../utils/formatValue';
import { getBackgroundColor } from '../utils/getBackgroundColor';

interface AgregadoresProps {
  agregadoresPorCampo: Map<number, HeadersProps>;
  col: HeadersProps;
  total: any;
  label: string;
}

export const Agregadores = React.memo(
  ({ agregadoresPorCampo, col, total, label }: AgregadoresProps) => {
    const cellRef = useRef<HTMLTableCellElement>(null);
    const textRef = useRef<HTMLSpanElement>(null);

    const formattedValue = total && `${formatValue(total)} (${label})`;

    const { onDynamicWidth, dynamicWidth } = useDataTable();

    useEffect(() => {
      if (textRef.current) {
        const textWidth = textRef.current.offsetWidth;
        const cellWidth = cellRef.current?.offsetWidth;

        if (cellWidth && textWidth > cellWidth) {
          const diff = textWidth - cellWidth;
          onDynamicWidth(col.codCampo, textWidth + diff);
        } else {
          onDynamicWidth(0, null);
        }
      }
    }, [col.codCampo, onDynamicWidth, formattedValue]);

    return (
      <TdAgregadores
        bgColor={getBackgroundColor(agregadoresPorCampo, col.codCampo)}
        width={dynamicWidth[col.codCampo] ?? col.width}
        ref={cellRef}
      >
        {formattedValue && (
          <span ref={textRef} style={{ whiteSpace: 'nowrap' }}>
            {formattedValue}
          </span>
        )}
      </TdAgregadores>
    );
  },
);

Agregadores.displayName = 'Agregadores';
