import React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { RxDividerVertical } from 'react-icons/rx';
import { SortCamposProps } from '~/components/Search/types';

import { useDataTable } from '../context/DataTableContext';
import { Th } from '../styles';
import { HeadersProps } from '../types';

interface TableHeaderCellProps {
  column: HeadersProps;
  isLast: boolean;
  handleSort: (field: string) => void;
  sortCampos: SortCamposProps[];
}

/**
 * Componente funcional que representa uma célula de cabeçalho de tabela.
 *
 * @component
 * @example
 * // Exemplo de uso:
 * <TableHeaderCell column={headerData} isLast={false} />
 *
 * @param {HeadersProps} column - Objeto que contém as propriedades da coluna do cabeçalho.
 * @param {boolean} [isLast=false] - Indica se a célula do cabeçalho é a última na linha.
 * @param {function} handleSort - Função de callback para ordenar a tabela com base no campo fornecido.
 * @param {SortCamposProps[]} sortCampos - Array de informações sobre ordenação.
 * @returns {React.Element} Retorna o JSX representando a célula do cabeçalho da tabela.
 */

export const TableHeaderCell = React.memo(
  ({ column, isLast, handleSort, sortCampos }: TableHeaderCellProps) => {
    const { dynamicWidth } = useDataTable();

    const headerId = `header-${column.codCampo}`;

    const sortInfo = sortCampos.find((sort) => sort.field === column.field);

    const renderSortIcon = () => {
      if (sortInfo) {
        if (sortInfo.sort === 'asc')
          return (
            <IoMdArrowRoundUp aria-label="Ordenar por ASC" color="#00000099" />
          );
        if (sortInfo.sort === 'desc')
          return (
            <IoMdArrowRoundDown
              aria-label="Ordenar por DESC"
              color="#00000099"
            />
          );
      }
      return null;
    };

    const sortColumn =
      dynamicWidth &&
      (dynamicWidth[column.codCampo] ?? 0) <= 120 &&
      column.width <= 100 &&
      column.headerName.length > 4;

    const sliceLength = (dynamicWidth[column.codCampo] ?? 0) <= 100 ? 3 : 7;

    const headerName = sortColumn
      ? `${column.headerName.slice(0, sliceLength)}...`
      : column.headerName;

    return (
      <Th
        scope="col"
        aria-labelledby={headerId}
        key={column.codCampo}
        width={dynamicWidth[column.codCampo] ?? column.width}
        onClick={() => handleSort(column.field)}
        title={sortColumn ? column.headerName : undefined}
      >
        <div>
          <span>
            {headerName}
            {renderSortIcon()}
            {sortInfo?.sort === null && (
              <IoMdArrowRoundUp
                className="TbArrowsUpDown"
                aria-label="Ordenar colunas"
                color="#00000099"
              />
            )}
          </span>
          {!isLast && <RxDividerVertical aria-label="Separador vertical" />}
        </div>
      </Th>
    );
  },
);

/**
 * Nome de exibição do componente, utilizado para otimizações de renderização.
 * @type {string}
 */

TableHeaderCell.displayName = 'TableHeaderCell';
